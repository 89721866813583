import React, { useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/Layout"
import Plus from "../images/plus-solid.svg";
import Minus from "../images/minus-solid.svg";

import Introduction from "../components/Global/Introduction"

// import Experience from "../components/About/Experience"
// import SubscribeTemplate from "../components/Global/SubscribeTemplate"
import AppointmentTemplate from "../components/Global/AppointmentTemplate"

import "../components/About/AboutUsTemplate.css";




function AboutUsTemplate(props) {
    console.log(props)
    let data = props.data.wpgraphql;

    const [activeOverview, setActiveOverview] = useState(0);


    function constructMetaData(page, currentPage) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://nextyounutrition.gr/${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://nextyounutrition.gr/${currentPage}`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    console.log(data)
    return (
        <Layout header={data.menu} footer={data.footer} appointment={data.appointment.appCpt} metaData={constructMetaData(data.page, props.pageContext.currentPage)} >
            <div>
                <section>
                    <Introduction backImg={data.page.aboutPageAcf.introductionBackgroundImage} title={data.page.aboutPageAcf.introductionHeader} />
                </section>
                <section className="container m-auto px-4 mt-24">
                    <div>
                        <Img fluid={data.page.aboutPageAcf.firstSectionImage.imageFile.childImageSharp.fluid} alt={data.page.aboutPageAcf.firstSectionImage.altText} className="m-auto about-img w-full md:w-1/2" />
                        <div className="content-wrapper" dangerouslySetInnerHTML={{ __html: data.page.content }}></div>
                        <div className="my-12">
                            {data.page.aboutPageAcf.customCv.map((f, i) => (
                                <div key={`overview-${i}`}>
                                    <div className={`overview-q flex justify-between items-center cursor-pointer ${activeOverview === i ? "overview-active" : ""}`} onClick={() => setActiveOverview(i)}>
                                        <h5>{f.title}</h5>
                                        <div>
                                            {activeOverview !== i
                                                ?
                                                <img src={Plus} style={{ width: "13px", marginRight: "5px" }} />
                                                :
                                                <img src={Minus} style={{ width: "13px", marginRight: "5px" }} />
                                            }

                                        </div>
                                    </div>
                                    {activeOverview === i && (
                                        <div className="py-4 pl-4 md:pl-8 ab-table" style={{borderSpacing: "10px"}}>
                                            {f.content.map((item,j) => (
                                                <div className={`ab-tr ${ j!==0 ? "pt-4" : "pt-0"}`} key={`td-${j}`}>
                                                    <div className="ab-td">
                                                        {item.title}
                                                        <span className="float-right">:</span>
                                                    </div>
                                                    <div className="ab-td pl-2 md:pl-4" dangerouslySetInnerHTML={{__html: item.content}}></div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                {/* <section className="mt-24">
                    <Experience backImg={data.page.aboutPageAcf.experienceBackgroundImage} content={data.page.aboutPageAcf.philosophyContent} gallery={data.page.aboutPageAcf.rightTabGallery} />
                </section> */}
                {/* <section className="container m-auto px-4 my-32">
                    <SubscribeTemplate data={data.template} />
                </section> */}
                <section>
                    <AppointmentTemplate
                        cpt={data.appointment.appCpt}
                        faq={data.faq.faqCpt}
                    />
                </section>
            </div>
        </Layout>
    )
}

export default AboutUsTemplate

export const pageQuery = graphql`
    query GET_ABOUT_US($id: ID!) {
        wpgraphql {
            page(id: $id) {
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                breadcrumbAcf{
                    breadcrumbName
                }
                content
			    aboutPageAcf{
                    introductionHeader
                    introductionBackgroundImage {
                        sourceUrl
                        altText
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 2000, quality:100){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                                
                            }
                        }
                    }

                    firstSectionImage {
                        sourceUrl
                        altText
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 900, quality:100){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                    experienceBackgroundImage {
                        sourceUrl
                        altText
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 2080, quality:100){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                    philosophyContent
                    experienceList {
                        item {
                            percentage
                            value
                          }
                    }
                    philosophyContent
                    rightTabGallery {
                        galleryItem {
                            itemParagraph
                            itemTitle
                            itemImage {
                                sourceUrl
                                altText
                                imageFile{
                                    childImageSharp{
                                        fluid(maxWidth: 685, quality:100){
                                            base64
                                            aspectRatio
                                            src
                                            srcSet
                                            srcWebp
                                            srcSetWebp
                                            sizes
                                        }
                                    }
                                }
                            }
                        }
                    }
                    customCv{
                        title
                        content{
                            title
                            content
                        }
                    }
                }
            }
            template(id: "subscribe-template", idType: SLUG) {
                subscribeTemplate{
                    inputField{
                      label
                      errorMessage
                    }
                    sendButton
                    titleContent
                }
            }
            appointment: template(id: "cG9zdDoyMDUw"){
                appCpt {
                    ...AppointmentFragment
                }
            }
            faq: template(id: "cG9zdDoyMTA5"){
                faqCpt{
                    ...FaqFragment
                }
            }
            menu(id:"bmF2X21lbnU6MjI="){
                menuItems{
                    edges{
                        node{
                            url
                            label
                            childItems {
                                edges {
                                    node {
                                        label
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
            footer: template(id:"cG9zdDoyMjE5"){
                id
                title
                footerInformationTemplate{
                    address{
                        addressGoogleLink
                        addressLabel
                    }
                    hoursLabel
                    hoursTitle
                    informationTitle
                    supportEmail
                    telephoneNumber
                }
                footerAcf{
                    googlaMapsImage{
                        sourceUrl
                        altText
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 750, quality:100){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                    city
                    callToActionText
                }
            }
        }
    }
`
